<template>
  <div>
    <template v-if="courier">
      Driver: {{ courier.name }}
      <v-btn icon :href="url" target="_blank"><v-icon>mdi-map</v-icon> </v-btn>

      <a :href="delivery.tracking_url" target="_blank">Track</a>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    delivery: {
      type: Object,
      default: null
    }
  },
  computed: {
    courier() {
      return this.delivery.courier
    },
    pickup() {
      return this.delivery.pickup
    },
    dropoff() {
      return this.delivery.dropoff
    },
    url() {
      // return `https://maps.googleapis.com/maps/api/staticmap?center=${this.courier.location.lat},${this.courier.location.lng}&zoom=13&size=600x400&maptype=roadmap&markers=color:green%7Clabel:Pickupf%7C${this.pickup.location.lat},${this.pickup.location.lng}&markers=color:blue%7Clabel:Dropoff%7C${this.dropoff.location.lat},${this.dropoff.location.lng}&markers=color:red%7Clabel:Driver%7C${this.courier.location.lat},${this.courier.location.lng}&key=${process.env.VUE_APP_API_KEY}`
      if (
        this.delivery.status === 'pickup_complete' ||
        this.delivery.status === 'dropoff'
      ) {
        return `https://www.google.com/maps/dir/${this.courier.location.lat},${this.courier.location.lng}/${this.dropoff.location.lat},${this.dropoff.location.lng}`
      }

      return `https://www.google.com/maps/dir/${this.courier.location.lat},${this.courier.location.lng}/${this.pickup.location.lat},${this.pickup.location.lng}`
    }
  }
}
</script>
